import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import ExpandableSection from '../components/expandable-section';

type ConsumerInfo = {
  allConsumerInfoYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
  allConsumerConditionsYaml: {
    edges: {
      node: {
        title: string;
        content: string;
      };
    }[];
  };
};

const query = graphql`
  query {
    allConsumerInfoYaml {
      edges {
        node {
          title
          content
        }
      }
    }
    allConsumerConditionsYaml {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

const ConsumerInfo: React.FC = () => {
  const data = useStaticQuery<ConsumerInfo>(query);
  const consumerInfo = data.allConsumerInfoYaml.edges.map(({node}) => node);
  const consumerConditions = data.allConsumerConditionsYaml.edges[0].node;

  return (
    <Layout>
      <Container className="general">
        <PageTitle title="Інформація для споживача" />

        {consumerInfo.map(({title, content}) => (
          <ExpandableSection key={title} summary={title} innerHTML={content} />
        ))}
        <ExpandableSection summary={consumerConditions.title} innerHTML={consumerConditions.content} />
      </Container>
    </Layout>
  );
};

export default ConsumerInfo;
